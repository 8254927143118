import React from 'react'
import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe , FaUserMd } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import Title from '../layouts/Title';
import Card from './Card';

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="Experiences" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="General Surgery"
          des="General Surgeon With 20 Years Of Rich Experience. Highly Trained In Performing All Types Of General Surgical Procedures"
         
        />
        <Card
          title="Surgical Oncology"
          des="An Expert In Surgery For All Types Of Tumors And Anything Related To Them"
          icon={<FaUserMd />}
        />
        <Card
          title="Advanced Laparoscopy"
          des="Expert In Advanced Laparoscopy Operations, Rich And Full Of Experience In Various Operations"
          icon={<FaUserMd />}
        />
        <Card
          title="Trauma Surgeries"
          des="Experienced In Dealing With Emergency Cases, Operating Procedures, And Follow-Up"
          icon={<FaUserMd />}
        />
        <Card
          title="Breast Surgery"
          des="Expert In Dealing With Breast Defects And Correcting Them Through Operations"
          icon={<FaUserMd />}
        />
        <Card
          title="Thyroid Surgery"
          des="An Expert In Dealing With The Thyroid Gland And Performing Corrective Operations"
          icon={<FaUserMd />}
        />
      </div>
    </section>
  );
}

export default Features