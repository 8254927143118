import React, { useState, useEffect } from 'react';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaLinkedinIn, FaSyringe, FaNotesMedical, FaStethoscope, FaUserNurse , FaYoutube } from "react-icons/fa";

const LeftBanner = () => {
    const [isAnimationFinished, setIsAnimationFinished] = useState(() => {
        return localStorage.getItem('isAnimationFinished') === 'true';
    });

    const [text] = useTypewriter({
        words: ["Consultant Surgeon"],
        loop: false,
        typeSpeed: 20,
        deleteSpeed: 10,
        delaySpeed: 2000,
        onLoopDone: () => setIsAnimationFinished(true),
    });

    useEffect(() => {
        // Save the animation state to localStorage
        if (isAnimationFinished) {
            localStorage.setItem('isAnimationFinished', 'true');
        }
    }, [isAnimationFinished]);

    return (
        <div className="w-full lgl:w-1/2 flex flex-col gap-20">
            <div className="flex flex-col gap-5">
                <h4 className="text-lg font-normal">WELCOME TO MY WEB PAGE</h4>
                <h1 className="text-6xl font-bold text-white">
                    <span className="text-designColor capitalize">Dr. Mahmoud Alajhar</span>
                </h1>
                {/* <h2 className="text-4xl font-bold text-white">
                    <span>{isAnimationFinished ? "Consultant Surgeon" : text}</span>
                    {!isAnimationFinished && (
                        <Cursor
                            cursorBlinking="false"
                            cursorStyle="|"
                            cursorColor="#ff014f"
                        />
                    )}
                </h2> */}
                <p className="text-base font-bodyFont leading-6 tracking-wide">
                    A highly experienced surgeon with over 20 years of experience in a wide range of surgical
                    procedures including all types of general surgery, both laparoscopy and conventional techniques,
                    more interested in trauma surgery and breast surgery.
                </p>
            </div>
            <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
                <div>
                    <h2 className="text-base uppercase font-titleFont mb-4">
                        Find me in
                    </h2>
                    <div className="flex gap-4">
                        <a href="https://www.youtube.com/@mahmoudalajhar4747" className="bannerIcon" target="_blank" rel="noopener noreferrer">
                            <FaYoutube />
                        </a>
                        <a href="https://www.facebook.com/mahmoud.alajhar" className="bannerIcon" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF />
                        </a>
                        <a href="https://linkedin.com/in/mahmoud-alajhar-03360a95/" className="bannerIcon" target="_blank" rel="noopener noreferrer">
                            <FaLinkedinIn />
                        </a>
                    </div>
                </div>
                <div>
                    <h2 className="text-base uppercase font-titleFont mb-4">
                        MY SKILLS
                    </h2>
                    <div className="flex gap-4">
                        <span className="bannerIcon">
                            <FaSyringe />
                        </span>
                        <span className="bannerIcon">
                            <FaNotesMedical />
                        </span>
                        <span className="bannerIcon">
                            <FaStethoscope />
                        </span>
                        <span className="bannerIcon">
                            <FaUserNurse />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeftBanner;
