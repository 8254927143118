import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree , zero  } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="Dr.Mahmoud Alajhar , Consoltant General Suregon"
          des="Other Experiences"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Colorectal perations"
          des=" Highly Skilled And Experienced In Colorectal Operations, Demonstrating Exceptional Proficiency In Managing Complex Cases With Utmost Care And Precision"
          src={projectOne}
        />
        <ProjectsCard
          title="Gastric Ballon"
          des=" Exhibiting Extensive Expertise And Mastery In Gastric Balloon Operations, Employing Cutting-Edge Techniques Such As Endoscopy And Ellipse, Ensuring Optimal Outcomes With Precision And Diligence"
          src={projectTwo}
        />
        <ProjectsCard
          title="Gastroscopi
          "
          des=" High Experience in Gastric Endoscopy Operations, Demonstrating Profound Expertise and Skill in Utilizing Advanced Techniques for Optimal Patient Care and Treatment"
          src={projectOne}
        />
        <ProjectsCard
          title="Guidelines Follow-Up"
          des=" Ensuring Comprehensive Monitoring and Adherence to Protocols, Exhibiting Expertise in the Procedure and its Subsequent Management"
          src={projectTwo}
        />
        <ProjectsCard
          title="Trauma Leader"
          des=" Esteemed Expert Renowned For Unparalleled Proficiency And Leadership In Navigating Critical Cases With Precision And Dedication, Ensuring Optimal Patient Outcomes"
          src={projectOne}
        />
        <ProjectsCard
          title="Ultrasound Guidance"
          des=" Recognized for Exceptional Proficiency and Skill in Utilizing Ultrasound Techniques, Demonstrating Precision and Expertise in Patient Care"
          src={projectTwo}
        />
      </div>
    </section>
  );
}

export default Projects