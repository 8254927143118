import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2010 - 2022</p>
          <h2 className="text-3xl md:text-4xl font-bold">Higher Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Zulikha  Hospital & Almadar Medical Center "
            subTitle="Consultant Surgeon , Sharjah (2023 - Present)"
            result="UAE"
            des="A Logbook That Includes Many Colon Surgeries Such As Laparoscopic
            Hemicolectomy That Includes Cases Of Colon Cancers, Addition To Various
            Common Surgeries"
          />
          <ResumeCard
            title="Colligo & Asjnia AB"
            subTitle="Consultant Suregon , Växjö. Stockholm (2020 - 2023)"
            result="Sweden"
            des="I Worked As a Senior Consultant In The Field Of General Surgery, Providing My Medical Proposals"
          />
          <ResumeCard
            title="SÄS Hospital"
            subTitle=" BORÅS , Sweden (2015 - 2020)"
            result="Sweden"
            des="I Worked As a Specialized Consultant And Expert In The Field of General Surgery. I Provided Many Types of Treatment Using The Latest Technologies And Supervised Several Resident Doctors"
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2004 - 2014</p>
          <h2 className="text-3xl md:text-4xl font-bold">Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Military Hospital"
            subTitle="General Surgeon , Trauma leader , Syria (2010 - 2014)"
            result="Syria"
            des="I Performed More Than 1,000 Trauma-Related Surgeries With Good Results, And I Controlled Alot Of Accidents That Caused Harm To People And Stopped Bleeding"
          />
          <ResumeCard
            title="Private Clinic in Private Hospital"
            subTitle="General Surgeon , Damascus (2006 - 2010)"
            result="Syria"
            des="I Worked As a Consultant For Patients, Providing Them With Professional Procedures And Complete Control of The Medical Condition"
          />
          <ResumeCard
            title="Hospitals of Ministry of Health  "
            subTitle="General Surgeon (Resident) , Damascus (2004 - 2009)"
            result="Syria"
            des="I Worked As a Resident General Surgeon In Damascus Hospitals With Precision And Determination At Work"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
