import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">1996 - 2015</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education Quality</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Swedish License"
            subTitle="Närhälsan, Lidkoping            (2014 - 2015)"
            result="4.00/4"
            des="Obtained Swedish License and Became a Member of the Swedish Doctors Fellowship"
          />
          <ResumeCard
            title="General Surgery Residency"
            subTitle="Ministry Of Health In Syria, Damascus (2005 - 2009)"
            result="5.00/5"
            des="Completed Residency in General Surgery, Obtaining the Title Of Specialist in General Surgery"
          />
          <ResumeCard
            title="Damascus University"
            subTitle="Damascus University , Damascus (1996 - 2003)"
            result="5.00/5"
            des="Graduated from Medical School with Specialization in General Practice , Graduated As A General Practitioner"
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2010 - 2022</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Zulikha Hospital , Almadar  Medical Center"
            subTitle="Consultant Surgeon , Sharjah (2023 - Present)"
            result="UAE"
            des="Working as a Consultant in Multiple Fields, Including Laparoscopic Hemicolectomy for Colon Cancers and Various Common Surgeries"
          />
          <ResumeCard
            title="Colligo And Asjnia AB"
            subTitle="Consultant Suregon , Växjö Stockholm (2020 - 2023)"
            result="Sweden"
            des="Working as an Official Consultant in the Department of General Surgery"
          />
          <ResumeCard
            title="SÄS Hospital"
            subTitle="SÄS Hospital, Borås , Sweden (2015-2020)"
            result="Sweden"
            des="Working As A Specialist Consultant In General Surgery At SÄS Hospital , Borås , Sweden"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
